const Messages: Record<string, string> = {
    '': '',
    'invalid.request': 'Petición inválida',
    'login.user_required': 'Usuario requerido',
    'login.pass_required': 'Contraseña requerida',
    'report.dashboard.founds.title': 'Total fondo de ahorro',
    'report.dashboard.founds.shares': 'Acciones',
    'report.dashboard.founds.social': 'Fondo social',
    'report.dashboard.founds.penalties': 'Multas',
    'report.dashboard.founds.loans': 'Préstamos',
    'report.dashboard.groups.title': 'Grupos de ahorro',
    'report.dashboard.groups.active': 'Activo',
    'report.dashboard.groups.inactive': 'Inactivos',
    'report.dashboard.age.title': 'Edad',
    'report.dashboard.age.0-9': 'Hasta 10 años',
    'report.dashboard.age.10-19': '10-19',
    'report.dashboard.age.20-29': '20-29',
    'report.dashboard.age.30-39': '30-39',
    'report.dashboard.age.40-49': '40-49',
    'report.dashboard.age.50-59': '50-59',
    'report.dashboard.age.60-69': '60-69',
    'report.dashboard.age.70-79': '70-79',
    'report.dashboard.age.80-89': '80-89',
    'report.dashboard.age.90-99': '90-99',
    'report.dashboard.age.100-109': '100-109',
    'report.dashboard.age.xLabel': 'Cantidad',
    'report.dashboard.gender.title': 'Género',
    'report.dashboard.gender.M': 'Hombre',
    'report.dashboard.gender.F': 'Mujer',

    'F': "Mujer",
    'M': "Hombre",

    'login.title': 'Bienvenido a Kakuaa',
    'login.user_placeholder': 'Usuario',
    'login.pass_placeholder': 'Contraseña',
    'login.login_button': 'Ingresar',
    'login.login_working': 'Ingresando',

    'MONDAY': 'lunes',
    'TUESDAY': 'martes',
    'WEDNESDAY': 'miércoles',
    'THURSDAY': 'jueves',
    'FRIDAY': 'viernes',
    'SATURDAY': 'sábado',
    'SUNDAY': 'domingo',

    'WEEKLY': 'Semanas',
    'MONTHLY': 'Meses',
    'DAYS': 'Días',

    /**
     * Presidenta
     * Registradora
     * Contadora 1
     * Contadora 2
     * Portadora de caja
     * Llavera 1
     * Llavera 2
     * Llavera 3
     * Socia
     */
    'root_members.1': 'PRESIDENTA',
    'root_members.2': 'REGISTRADORA',
    'root_members.3': 'CONTADORA 1',
    'root_members.4': 'CONTADORA 2',
    'root_members.5': 'LLAVERA 1',
    'root_members.6': 'LLAVERA 2',
    'root_members.7': 'LLAVERA 3',
    'root_members.8': 'PORTADORA DE CAJA',
    'root_members.9': 'SOCIA 9',
    'root_members.10': 'SOCIA 10',
    'root_members.11': 'SOCIA 11',
    'root_members.12': 'SOCIA 12',
    'root_members.13': 'SOCIA 13',
    'root_members.14': 'SOCIA 14',
    'root_members.15': 'SOCIA 15',
    'root_members.16': 'SOCIA 16',

    'PRESIDENT': 'PRESIDENTA',
    'REGISTER': 'REGISTRADORA',
    'ACCOUNTANT_1': 'CONTADORA 1',
    'ACCOUNTANT_2': 'CONTADORA 2',
    'KEY_KEEPER_1': 'LLAVERA 1',
    'KEY_KEEPER_2': 'LLAVERA 2',
    'KEY_KEEPER_3': 'LLAVERA 3',
    'BOX_KEEPER': 'PORTADORA DE CAJA',
    'MEMBER': 'SOCIA',
    'REMOVED': 'Eliminada',

    'root_members.add': 'Agregar miembro',

    'root_penalties.add': 'Agregar multa',

    'error.required': 'Campo requerido',
    'error.minimum': 'Valor por debajo de lo permitido',
    'error.type': 'Debe ser numérico',

    'report.dashboard.location.title': 'Por ciudad',
    'report.by_group.founds.title': 'Montos promedio por sesión',
    'report.by_group.founds.shares': 'Acciones',
    'report.by_group.founds.social': 'Fondo social',
    'report.by_group.founds.penalties': 'Multas',
    'report.by_group.founds.loans': 'Préstamos',

    'report.by_group.founds.xLabel': 'Gs.',

    'report.by_group.location.title': 'Por ciudad',
    'report.by_group.age.title': 'Edad',
    'report.by_group.age.0-9': 'Hasta 10 años',
    'report.by_group.age.10-19': '10-19',
    'report.by_group.age.20-29': '20-29',
    'report.by_group.age.30-39': '30-39',
    'report.by_group.age.40-49': '40-49',
    'report.by_group.age.50-59': '50-59',
    'report.by_group.age.60-69': '60-69',
    'report.by_group.age.70-79': '70-79',
    'report.by_group.age.80-89': '80-89',
    'report.by_group.age.90-99': '90-99',
    'report.by_group.age.100-109': '100-109',
    'report.by_group.age.xLabel': 'Cantidad',
    'report.by_group.gender.title': 'Género',
    'report.by_group.gender.M': 'Hombre',
    'report.by_group.gender.F': 'Mujer',

    'sharedFoundAmount': 'Fondo social',
    'maxShares': 'Cantidad máxima de acciones',
    'penalties': 'Multas',
    'NotNull': 'requerido',
    'extra_funds': 'Fondos extra',
    'Size.10.3': 'deben ser mas de 3 y menos de 10',
    'Size.3.10': 'deben ser mas de 3 y menos de 10',
    'Size.8.10': 'deben ser mas de 8 y menos de 10',
    'Size.10.8': 'deben ser mas de 8 y menos de 10',
    'Min.1': 'deben ser mayor a 1',
    'Max.10': 'deben ser menor a 10',

    'user.duplicated': 'Una cédula ingresada tiene dos usuarios, contacte al ADMIN',

    'campaign.title': 'Campañas',
    'campaign.name': 'Nombre',

    'add_admin.name': 'Nombre',
    'add_admin.username': 'Usuario (usado en ingreso)',
    'add_admin.password1': 'Contraseña',
    'add_admin.password2': 'Repita la contraseña',
    'add_admin.role': 'Rol',
    'add_admin.campaign': 'Campaña',
    'generic.add': 'Agregar',
    'generic.edit': 'Editar',
    'generic.working': 'Cargando',
    'generic.saving': 'Guardando',
    'generic.cancel': 'Cancelar',
    'generic.save': 'Guardar',
    'generic.required': 'Campo requerido',
    'generic.ok': 'Aceptar',

    'error.format': 'Datos inválidos',


    'ADMIN': 'Administración',
    'MANAGER': 'Coordinación',
    'SUPERVISOR': 'Facilitación',

    'user.already.exists': 'Un usuario con esa cédula ya existe',

    'report.dashboard.location.xLabel': 'Grupos',
    'report.dashboard.location.xLabel2': 'Participantes',

    'edit_group.supervisor': 'Facilitador',

    'PRESENT': 'Presente',
    'ABSENT': "Ausente",

    'invalid.image.size': 'Foto muy grande, debe ser de como máximo 2 megas',
    'invalid.image.type': 'Foto de formato incorrecto, debe ser JPG o PNG'

}


export function t(key: string): string {
    if ((key || '').startsWith('root_members.')) {
        const pos = parseInt(key.substring(key.indexOf('.') + 1));
        if (pos >= 16) return `SOCIA ${pos}`;
    }
    const toRet = Messages[key];
    if (toRet == null) console.log(`'${key}': ''`);
    return toRet || key;
}

export function role(pos: number | undefined): string {
    if (pos === undefined) return '';
    if (pos === -1) return t('REMOVED')
    return t('root_members.' + pos)
}
