import {BasePage} from "../components/layout/BasePage";
import {Button, Col, message, Row, Space} from "antd";
import {usePaginatedTable} from "../hooks/usePaginatedTable";
import {DynamicTable} from "../components/DynamicTable";
import {age, formatDate} from "../formatter";
import {useBoolean} from "../hooks/useBool";
import React, {useEffect, useMemo} from "react";
import {t} from "../Messages";
import {useUser} from "../AuthContext";
import {ApiError} from "../Api";
import {Link} from "react-router-dom";
import {ReportRequest, ReportRequestParam} from "../Model";
import {useApi} from "../useApi";
import {extractReport} from "./DashboardPage";
import {PieReport} from "../components/report/PieReport";
import {useFilter} from "../FilterContext";
import {html2PDF, Opts} from "../util/Pdf2Canva";
import {jsPDF} from "jspdf";

export interface DTEndUser {
    id: number;
    groups: Array<{
        id: number;
        name: string;
        cycle: number;
    }>;
    document: string;
    created_at: string;
    name: string;
    last_name: string;
    full_name: string;
    phone: string;

    group_id: never;
    group_city: never;
    group_department: never;
    campaign_id: never;
}

export function EndUsersPage() {

    const {user: {role}} = useUser();

    function generatePdf() {
        const div = document.querySelector("#reports");
        if (!div) {
            message.error("No se puede generar reporte, contacta con el Admin");
            return;
        }
        html2PDF(div as unknown as HTMLElement, {
            output: 'participants.pdf',
            margin: {
                top: 100,
                bottom: 100,
                left: 20,
                right: 20
            },
            init: (opts: Opts, pdf: jsPDF) => {
                pdf.text("Kakua", 10, 10);
            }
        })
    }

    return <BasePage>
        <Row gutter={[24, 24]}>
            {role !== 'SUPERVISOR' && <Col xs={24}>
                <EndUserDashboard/>
            </Col>}
            {role !== 'SUPERVISOR' && <Col xs={24}>
                <Button onClick={generatePdf}>Descargar reportes</Button>
            </Col>}
            <Col xs={24}>
                <EndUserTable/>
            </Col>
        </Row>
    </BasePage>
}

export function EndUserDashboard() {
    const {filter} = useFilter();

    const params: ReportRequest = useMemo(() => {
        const p: Record<string, ReportRequestParam> = {
            campaign: filter.campaign,
            group: filter.group,
            end_user: filter.endUser,
            date: filter.date,
            city: filter.city,
            department: filter.department
        };
        return {
            'report.dashboard.founds': p,
            'report.dashboard.groups': p,
            'report.dashboard.location': p,
            'report.dashboard.age': p,
            'report.dashboard.gender': p,
        }
    }, [filter]);


    const reports = useApi('report', 'DASHBOARD', params);
    const totals = extractReport('report.dashboard.founds', reports);
    const groups = extractReport('report.dashboard.groups', reports);
    const gender = extractReport('report.dashboard.gender', reports);

    return <Row gutter={[24, 24]} align="middle" justify="center" id="reports">
        <Col xs={8}>
            <div style={{background: 'white'}}>
                <PieReport report={totals}/>
            </div>
        </Col>
        <Col xs={8}>
            <div style={{background: 'white'}}>
                <PieReport report={gender}/>
            </div>
        </Col>
        <Col xs={8}>
            <div style={{background: 'white'}}>
                <PieReport report={groups}/>
            </div>
        </Col>
    </Row>
}

export function EndUserTable() {

    const {filter} = useFilter();
    const data = usePaginatedTable<DTEndUser>('end_users', {}, true, 'end_user_report');

    useEffect(() => {
        data.updateFilters({
            id: filter.endUser,
            group_id: filter.group,
            campaign_id: filter.campaign,
            group_city: filter.city,
            group_department: filter.department
        })
        // eslint-disable-next-line
    }, [filter])
    const working = useBoolean(false);
    const {api} = useUser();

    function resetPIN(document: string) {
        working.setTrue();
        api.resetPin(parseInt(document))
            .then(r => {
                message.success({content: `PIN de ${r.name} reseteado`, key: 'reset-pin', duration: 5});
            })
            .catch(e => {
                if (e instanceof ApiError && e.asSimpleCode() === 404) {
                    message.info({content: 'Usuario no encontrado', key: 'reset-pin', duration: 5});
                    return
                }
                message.error({content: 'Error al resetear PIN', key: 'reset-pin', duration: 5});
            })
            .finally(working.setFalse)
    }

    return <Row gutter={6} align="middle" justify="center" style={{background: 'white'}}>
        <DynamicTable<DTEndUser>
            title="Usuarios del sistema"
            table={data}
            columns={[
                {
                    dataIndex: 'document', title: 'Cédula',
                    render: (_, r) => <Link to={`/users/${r.id}`}>{r.document}</Link>
                },
                {dataIndex: 'created_at', title: 'Registro', render: formatDate},
                {dataIndex: 'name', title: 'Nombre', render: (_, r) => `${r.name} ${r.last_name || ''}`},
                {dataIndex: 'birth_date', title: 'Edad', render: age},
                {dataIndex: 'gender', title: 'Género', render: t},
                {dataIndex: 'phone', title: 'N de celular'},
                {
                    dataIndex: 'groups', title: 'Comités', render: (_, r) =>
                        <Space direction="vertical">
                            {r.groups.map(g => <Link to={`/groups/${g.id}`} key={g.id}>
                                {g.name} ({g.cycle})
                            </Link>)}
                        </Space>

                },
                {
                    dataIndex: 'id', title: '', render: (id, r) => <>
                        <Space>
                            <Button loading={working.value} danger onClick={() => resetPIN(r.document)}>Reset
                                PIN</Button>
                        </Space>
                    </>
                },
            ]}/>
    </Row>
}


